import React from "react";
// Import the CSS file for styling

const TermsAndConditions = () => {
	return (
		<div className="terms-container">
			{/* <h1 className="terms-title">Terms and Conditions</h1> */}

			<p className="effective-date">Effective Date: November 25, 2024</p>

			<section className="terms-section">
				<h2 className="section-title">1. Use of the Website</h2>
				<ul className="section-list">
					<li>
						You must be at least 18 years old to use the Website. By using this
						Website, you represent that you are of legal age to form a binding
						contract.
					</li>
					<li>
						You agree to use the Website only for lawful purposes and in
						accordance with these Terms. You shall not:
					</li>
					<ul>
						<li>
							Use the Website in any way that could damage, disable, or impair
							the Website or interfere with any other user’s use.
						</li>
						<li>
							Engage in any fraudulent activity or use the Website for illegal
							purposes.
						</li>
						<li>
							Post, upload, or transmit any harmful content, including viruses
							or malware.
						</li>
					</ul>
				</ul>
			</section>

			<section className="terms-section">
				<h2 className="section-title">2. Account Registration</h2>
				<ul className="section-list">
					<li>
						To access certain features of the Website, you may be required to
						create an account.
					</li>
					<li>
						You agree to provide accurate, current, and complete information
						during the registration process.
					</li>
					<li>
						You are responsible for maintaining the confidentiality of your
						account and password and agree to notify us immediately if you
						suspect any unauthorized access.
					</li>
				</ul>
			</section>

			<section className="terms-section">
				<h2 className="section-title">3. Intellectual Property</h2>
				<ul className="section-list">
					<li>
						The Website and all its content, including but not limited to text,
						graphics, logos, images, and software, are owned by or licensed to
						Vector360 BD and are protected by copyright and intellectual
						property laws.
					</li>
					<li>
						You are granted a limited, non-exclusive, non-transferable license
						to use the Website for personal or business purposes in accordance
						with these Terms.
					</li>
				</ul>
			</section>

			<section className="terms-section">
				<h2 className="section-title">4. Product and Service Descriptions</h2>
				<ul className="section-list">
					<li>
						We make every effort to provide accurate descriptions of our
						services and products on the Website.
					</li>
					<li>
						However, we do not warrant that the descriptions are complete,
						accurate, or error-free.
					</li>
					<li>
						We reserve the right to modify, discontinue, or suspend the
						availability of any product or service at any time.
					</li>
				</ul>
			</section>

			<section className="terms-section">
				<h2 className="section-title">5. Pricing and Payments</h2>
				<ul className="section-list">
					<li>
						All prices are listed in [currency] and are subject to change
						without notice.
					</li>
					<li>
						You agree to pay the specified fees for any products or services
						purchased on the Website.
					</li>
					<li>
						Payments will be processed through the Vector360BD bank account.
					</li>
				</ul>
			</section>

			<section className="terms-section">
				<h2 className="section-title">6. Disclaimers</h2>
				<ul className="section-list">
					<li>
						The Website is provided on an "as-is" and "as-available" basis. We
						make no representations or warranties regarding the accuracy,
						reliability, or availability of the Website.
					</li>
					<li>
						We disclaim all warranties, express or implied, including but not
						limited to implied warranties of merchantability and fitness for a
						particular purpose.
					</li>
				</ul>
			</section>

			<section className="terms-section">
				<h2 className="section-title">7. Limitation of Liability</h2>
				<ul className="section-list">
					<li>
						In no event shall Vector360 BD, its affiliates, employees, or agents
						be liable for any indirect, incidental, special, or consequential
						damages, including but not limited to loss of profits, data, or
						business interruption, arising from the use or inability to use the
						Website or services.
					</li>
					<li>
						Our total liability for any claim related to the Website shall not
						exceed the amount paid by you, if any, for accessing the Website or
						services in the last 30 days prior to the claim.
					</li>
				</ul>
			</section>

			<section className="terms-section">
				<h2 className="section-title">8. Indemnification</h2>
				<ul className="section-list">
					<li>
						You agree to indemnify, defend, and hold harmless Vector360 BD, its
						affiliates, and their respective officers, employees, agents, and
						partners from any claims, damages, liabilities, or expenses
						(including legal fees) arising from your use of the Website,
						violation of these Terms, or infringement of third-party rights.
					</li>
				</ul>
			</section>

			<section className="terms-section">
				<h2 className="section-title">9. Termination</h2>
				<ul className="section-list">
					<li>
						We reserve the right to suspend or terminate your access to the
						Website at our discretion, without prior notice, if we believe you
						have violated these Terms.
					</li>
					<li>
						Upon termination, you must cease all use of the Website and destroy
						any downloaded content.
					</li>
				</ul>
			</section>

			<section className="terms-section">
				<h2 className="section-title">10. Governing Law</h2>
				<ul className="section-list">
					<li>
						These Terms shall be governed by and construed in accordance with
						the laws of Bangladesh, without regard to its conflict of law
						principles.
					</li>
					<li>
						Any disputes arising from these Terms or the Website shall be
						resolved in the courts located in [City], Bangladesh.
					</li>
				</ul>
			</section>

			<section className="terms-section">
				<h2 className="section-title">11. Changes to Terms</h2>
				<ul className="section-list">
					<li>
						We reserve the right to modify or replace these Terms at any time.
						The updated Terms will be posted on this page, and the "Effective
						Date" at the top will be revised.
					</li>
					<li>
						Your continued use of the Website after the changes take effect
						constitutes your acceptance of the updated Terms.
					</li>
				</ul>
			</section>

			<section className="terms-section">
				<h2 className="section-title">12. Contact Us</h2>
				<p>
					If you have any questions about these Terms, please contact us at:
				</p>
				<ul>
					<li>
						Email:{" "}
						<a href="mailto:support@vector360bd.com">support@vector360bd.com</a>
					</li>
					<li>
						Phone: <a href="tel:+8801790092209">+880 1790-092209</a>
					</li>
				</ul>
			</section>
		</div>
	);
};

export default TermsAndConditions;
