import React from "react";
// Import the CSS file for styling

const PrivacyPolicyWrapper = () => {
	return (
		<div className="privacy-policy-container">
			<p className="privacy-policy-title">
				<strong>Your Privacy is Important to Us</strong>
			</p>

			<section className="privacy-policy-section">
				<h2 className="section-title">Information We Collect</h2>
				<ul className="section-list">
					<li>
						<strong>Personal Information You Provide:</strong> This may include
						your name, email address, phone number, and other information you
						voluntarily provide when you contact us, sign up for our newsletter,
						or make a purchase.
					</li>
					<li>
						<strong>Information Automatically Collected:</strong> We may
						automatically collect information about your device, browser, and
						your activity on our website, such as your IP address, browser type,
						operating system, referring pages, and pages visited.
					</li>
				</ul>
			</section>

			<section className="privacy-policy-section">
				<h2 className="section-title">How We Use Your Information</h2>
				<ul className="section-list">
					<li>
						<strong>To Provide Our Services:</strong> We use your information to
						deliver the services you request, such as processing orders,
						responding to inquiries, and providing customer support.
					</li>
					<li>
						<strong>To Improve Our Website:</strong> We analyze your usage data
						to improve our website's functionality, user experience, and
						content.
					</li>
					<li>
						<strong>To Communicate with You:</strong> We may use your contact
						information to send you important notices, updates, and marketing
						communications.
					</li>
					<li>
						<strong>To Protect Our Business:</strong> We may use your
						information to prevent fraud, protect our rights, and comply with
						legal obligations.
					</li>
				</ul>
			</section>

			<section className="privacy-policy-section">
				<h2 className="section-title">Sharing Your Information</h2>
				<ul className="section-list">
					<li>
						<strong>Service Providers:</strong> We may share your information
						with third-party service providers who help us operate our business,
						such as payment processors, shipping carriers, and email marketing
						platforms.
					</li>
					<li>
						<strong>Legal Requirements:</strong> We may disclose your
						information to comply with legal obligations, such as responding to
						subpoenas or court orders.
					</li>
				</ul>
			</section>

			<section className="privacy-policy-section">
				<h2 className="section-title">Data Security</h2>
				<p>
					We implement reasonable security measures to protect your personal
					information from unauthorized access, use, or disclosure. However, no
					method of transmission over the internet or electronic storage is
					completely secure.
				</p>
			</section>

			<section className="privacy-policy-section">
				<h2 className="section-title">Your Rights</h2>
				<ul className="section-list">
					<li>
						<strong>Access Your Information:</strong> You can request access to
						the personal information we hold about you.
					</li>
					<li>
						<strong>Correct Your Information:</strong> You can request that we
						correct any inaccuracies in your personal information.
					</li>
					<li>
						<strong>Delete Your Information:</strong> You can request that we
						delete your personal information under certain circumstances.
					</li>
					<li>
						<strong>Object to Processing:</strong> You can object to the
						processing of your personal information in certain cases.
					</li>
				</ul>
			</section>

			<section className="privacy-policy-section">
				<h2 className="section-title">Contact Us</h2>
				<p>
					If you have any questions or concerns about our Privacy Policy, please
					contact us at:
				</p>
				<ul>
					<li>
						Email:{" "}
						<a href="mailto:support@vector360bd.com">support@vector360bd.com</a>
					</li>
					<li>
						Phone: <a href="tel:+8801790092209">+880 1790-092209</a>
					</li>
				</ul>
			</section>
		</div>
	);
};

export default PrivacyPolicyWrapper;
