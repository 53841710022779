import React, { useEffect } from "react";
import AnimateLine from "../../common/AnimateLine";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import TermsAndConditions from "./TermsWrapper";
import { useLocation } from "react-router-dom";
export default function Terms() {
	const location = useLocation(); // Get the current location

	useEffect(() => {
		// Scroll to top if the page location changes
		window.scrollTo(0, 0);
	}, [location]); // Only run when the location (URL) changes

	return (
		<div className="creasoft-wrap">
			<AnimateLine />
			<Breadcrumb
				pageName="Terms and Conditions"
				pageTitle="Terms and Conditions"
			/>
			<TermsAndConditions />
			<SubscribArea />
		</div>
	);
}
