import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
function ContactWrapper() {
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);
	const form = useRef();
	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);

		emailjs
			.sendForm("service_cvytgo8", "template_u9u7add", form.current, {
				publicKey: "ns1jLBDiM9uvCnL6l",
			})
			.then(
				() => {
					setMessage("Message Sent Successfully");
					setLoading(false);
					form.current.reset();
				},
				(error) => {
					console.log("FAILED...", error.text);
				}
			);
	};
	return (
		<>
			<section className="contact-area sec-mar">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-lg-5">
							<div className="contact-left">
								<div className="sec-title layout2">
									<span>Get in touch</span>
									<h2>Contact us if you have more questions.</h2>
								</div>
								<ul className="social-follow">
									<li>
										<a href="https://www.linkedin.com/company/vector360bd">
											{/* <i className="fab fa-twitter" /> */}
											<i className="bi bi-linkedin"></i>
										</a>
									</li>
									<li>
										<a href="https://www.instagram.com/vector360.bd">
											<i className="bi bi-instagram"></i>
										</a>
									</li>
									<li>
										<a href="https://www.facebook.com/people/Vector360/61562323470367">
											<i className="bi bi-facebook"></i>
										</a>
									</li>

									{/* <li>
                    <a
                      rel="noopener noreferrer"
                      href="https://www.pinterest.com/"
                    >
                      <i className="fab fa-pinterest-p" />
                    </a>
                  </li> */}
									{/* <li>
                    <a
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </li> */}
								</ul>
								<div className="informations">
									<div className="single-info">
										<div className="icon">
											<i className="fas fa-map-marker-alt" />
										</div>
										<div className="info">
											<h3>Location</h3>
											<p>
												<a href="https://maps.app.goo.gl/GGAXJqJYBpipJxP2A">
													Macro Lily, Plot 8/2, Block D, Lalmatia, Dhaka - 1207
												</a>
											</p>
										</div>
									</div>

									<div className="single-info">
										<div className="icon">
											<i className="fas fa-phone-alt" />
										</div>
										<div className="info">
											<h3>Phone</h3>
											<a href="tel:8801790092209">+880 1790092209</a>
											<a href="tel:16143000676">+1614-300-0676</a>
										</div>
									</div>
									<div className="single-info">
										<div className="icon">
											<i className="far fa-envelope" />
										</div>
										<div className="info">
											<h3>Email</h3>
											<a href="mailto:support@vector360bd.com">
												support@vector360bd.com
											</a>
											{/* <a href="tell:support@vector360bd.com">support@vector360bd.com</a> */}
										</div>
									</div>
									<div className="single-info">
										<div
											className="icon"
											style={{
												display: "flex",
											}}
										>
											<i
												className="bi bi-whatsapp"
												style={{
													fontSize: "25px",
												}}
											></i>
										</div>
										<div className="info">
											<h3>Whatsapp</h3>
											<a
												href="https://wa.me/+8801790092209 "
												target="_blank"
												rel="noopener noreferrer"
											>
												+880 1790092209
											</a>
											<a
												href="https://wa.me/+16143000676"
												target="_blank"
												rel="noopener noreferrer"
											>
												+1614-300-0676
											</a>
											{/* <a href="tell:support@vector360bd.com">support@vector360bd.com</a> */}
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* <div className="number">
										<div className="num-icon">
											<i className="bi bi-whatsapp" />
										</div>
										<div className="phone">
											<a
												href="https://wa.me/+8801790092209 "
												target="_blank"
												rel="noopener noreferrer"
											>
												+880 1790092209
											</a>
											<a
												href="https://wa.me/+16143000676"
												target="_blank"
												rel="noopener noreferrer"
											>
												+1614-300-0676
											</a>
										</div>
									</div> */}
						{/* <div className="col-md-6 col-lg-7">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.786329070249!2d90.36652007607216!3d23.754997788598406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755bf0db58c4f11%3A0x6974883a69c4bdbe!2sReserveit%20BD!5e0!3m2!1sen!2sbd!4v1713776614078!5m2!1sen!2sbd" />
                </div>
              </div>
            </div> */}
					</div>
					<div className="getin-touch">
						<div className="row">
							<div className="col-md-6 col-lg-7">
								<div
									className="contact-form wow animate fadeInUp"
									data-wow-delay="200ms"
									data-wow-duration="1500ms"
								>
									<h3>Have Any Questions</h3>
									<form onSubmit={handleSubmit} ref={form}>
										<div className="row">
											<div className="col-12">
												<input
													type="text"
													name="from_name"
													placeholder="Enter your name"
													required={true}
												/>
											</div>
											<div className="col-md-6">
												<input
													type="email"
													name="user_email"
													placeholder="Enter your email"
													required={true}
												/>
											</div>
											<div className="col-md-6">
												<input
													type="text"
													name="subject"
													placeholder="Subject"
													required={true}
												/>
											</div>
											<div className="col-12">
												<textarea
													name="message"
													cols={30}
													rows={10}
													placeholder="Your message"
													defaultValue={""}
													required={true}
												/>
												<input
													type="submit"
													defaultValue="Send Message"
													disabled={loading}
												/>
												<span style={{ marginLeft: "10px", color: "green" }}>
													{message}
												</span>
											</div>
										</div>
									</form>
								</div>
							</div>
							<div
								className="col-md-6 col-lg-5 wow animate fadeInUp"
								data-wow-delay="300ms"
								data-wow-duration="1500ms"
							>
								<div className="call-banner">
									<img
										src={process.env.PUBLIC_URL + "/img/contact-us.svg"}
										alt="images"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default ContactWrapper;
